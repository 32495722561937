import Head from 'next/head';
import React from 'react';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../src/apolloClient';
import { store } from '../src/app/store';
import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import './index.css';
import './studio.css';
import '@ozoneuniverse/mfe-core/dist/components/css3Dfix.css';

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  const apolloClient = useApollo(pageProps);

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <link rel='icon' href='/logo.jpeg' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <link rel='apple-touch-icon' href='%PUBLIC_URL%/logo192.png' />
        <title>Ozone Studio Mfe</title>
        <meta property='og:title' content={'Ozone Studio Platform'} />
        <meta
          name='og:description'
          content={
            'Build, design, and share web based virtual worlds like never before. Powered by Ozone platform. Multi blockchain interoperability built-in.'
          }
        />
        <meta property='og:image' content={'/preview.png'} />
        <meta property='og:image:width' content='1200'></meta>
        <meta property='og:image:height' content='630'></meta>
        <meta property='twitter:card' content='summary_large_image'></meta>
        <meta property='robots' content='all'></meta>
      </Head>
      <Script
        src='https://www.googletagmanager.com/gtag/js?id=G-XH33563KR9'
        strategy='afterInteractive'
      />
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-XH33563KR9');
      `}
      </Script>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#e20073',
            borderRadius: 12,
            fontSize: 14,
            colorHighlight: '#4a4ae5',
          },
          components: {
            Alert: {
              colorBorder: '#a0a0a0',
              colorBgBase: '#b3b3b3',
              colorIcon: '#4a4ae5',
            },
            Radio: {
              colorPrimary: '#000',
              colorPrimaryHover: '#663da9',
            },
            Switch: {
              colorPrimary: '#87d068',
            },
            Tabs: {
              itemActiveColor: 'rgba(92, 54, 154, 1)',
              itemSelectedColor: 'rgba(92, 54, 154, 1)',
              inkBarColor: 'rgba(92, 54, 154, 1)',
            },
          },
        }}
      >
        <ApolloProvider client={apolloClient}>
          <SessionProvider session={session}>
            <Provider store={store}>
              <Component {...pageProps} />
            </Provider>
          </SessionProvider>
        </ApolloProvider>
      </ConfigProvider>
    </>
  );
}
